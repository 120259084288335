import React from "react";


const Info = () => {
    return (
        <div className="info-log">
            <img src="/images/photologin.jpg" alt="img"/>
        </div>
    )
}

export default Info;