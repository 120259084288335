import Form from "../../components/Form"
import "./signup.css"

const SignUp = () => {
    return (
        <div className="container-sign">
            <Form />
            {/* <Info /> */}
        </div>
    )
}

export default SignUp