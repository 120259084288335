import React from "react";
import SignupForm from "./SignupForm";

const Form = () => {
    return (
        <div>
            <SignupForm />
        </div>
    );
};

export default Form;